import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { stepComponentHasConfigFlag } from '../../helpers/stepComponentHasConfigFlag';
import {
  DocumentComponentValidationLogic,
  escapeComponentJSONFieldName,
  generateValidationSchema,
  generateValidationSchemaForStep,
  unescapeComponentJSONFieldName,
} from '../../helpers/validation';
import { isReadyToContinue as underwritingMemoAddressIsReady } from '../../types/step-components/UnderwritingMemoAddress';

import type {
  ClaimWorkflowStepFragmentData,
  StepComponentRegistry,
} from '../../types';
import type { ValidationError } from 'yup';
/**
 * The stateful context provided to a step rendering stack. Includes the
 * current step data, the step component registry, and a set of methods for
 * updating the step's values and submitting the step.
 */
export type StepCtx = {
  /**
   * Data about the current step, its content, and where it falls in the
   * workflow.
   */

  valuesJSON: any;

  step: ClaimWorkflowStepFragmentData;

  /**
   * The mapping of step component type names to React components that render
   * those step component types.
   */
  registry: StepComponentRegistry;

  /**
   * The values associated with any fields in the step. For example, if the
   * step has a component that includes a `field` value of `age`, and that
   * component calls `updateValue('age', 21)`, then `values.age` will be `21`.
   */
  values: Record<string, any>;

  /**
   * Any validation errors associated with any fields in the step. For example,
   * if the step has a component that includes a `field` value of `phone_number`,
   * and that component's validation schema rejects the user's existing input,
   * then `errors.phone_number` will be populated with the error message.
   */
  errors: Record<string, string>;

  /**
   * The number of validation errors in the step. This is used to determine
   * how errors are displayed in the UI.
   */
  errorCount: number;

  /**
   * Updates the value of a field in the step. This is used by step components
   * to update the values of their fields.
   */
  updateValue: (k: string | undefined, v: any) => void;

  /**
   * Checks all the required field values and, if they are all present, calls
   * `submit` with the current values. If any required fields are missing, then
   * there is no effect.
   */
  attemptSubmit: () => void;

  /**
   * Validates all the field values according to the `validationSchema` and
   * updates the `errors` record with any errors. If there are no errors, then
   * it calls the provider's `onSubmit` callback with the current `values`.
   */
  submit: (values: Record<string, any>, _?: { force: boolean }) => void;

  /**
   * Reset the error-related state, and set `values` to the given record.
   */
  resetWithValues: (values: Record<string, any>) => void;

  /**
   * Skips the current step. This means calling the provider's `onSubmit` callback
   * with the current `values` without doing any validation. Steps may optionally
   * specify a key-value pair to be added to the `values` record when the step is
   * skipped, via the `skip_field` and `skip_value` properties on the `step.content`
   * object.
   *
   * Notably, this does not check the `stepSkippable` flag, assuming that the
   * skip button will not be rendered if the step is not skippable.
   */
  skip: () => void;

  isDirty: boolean;
  /**
   * Whether the current step can be skipped. This is determined by the presence
   * of a `skip_label` value on the `step.content` object.
   */
  stepSkippable: boolean;

  /**
   * Whether the current step requires manual confirmation to skip.
   */
  skipNeedsManualConfirm: boolean;

  /**
   * If the step requires manual confirmation before skipping, flag indicates if
   * the user has manually confirmed they want to skip the step.
   */
  skipConfirmed: boolean;

  /**
   * If the step requires manual confirmation before skipping, this function
   * should be called to indicate that the user has manually confirmed they want
   * to skip the step.
   */
  setSkipConfirmed: (confirmed: boolean) => void;

  /**
   * Whether the current step requires manual submission. This can be set in
   * several ways:
   * - If the `step.content.manual_submit_label` value is set
   * - If any React components registered to render this step's components
   *   have the `stepConfig.manualSubmit` flag set
   * - If this step contains any of a set of special components that require
   *   manual submission (namely `vehicle_damage_picker` and `interstitial`).
   * - The hideSubmitButton StepComponentConfigFlag is *not* set (which
   *   explicitly sets manualSubmitRequired to false)
   */
  manualSubmitRequired: string | boolean;

  /**
   * If true, the current step's title should not be rendered by the StepRenderer as
   * usual. This flag is set by adding the `stepConfig.controlsTitle` flag on a
   * StepComponentFC implementation.
   */
  stepControlledTitle: boolean;

  /**
   * If true, the step should be rendered in a special "full-page height" mode.
   * This flag is set by adding the `stepConfig.fullPageHeight` flag on a
   * StepComponentFC implementation.
   */
  stepRequiresFullPageHeight: boolean;

  /**
   * Indicates whether the submit button should be enabled. This is normally
   * `true`, since submission enforces validation on most fields, but this flag
   * provides special handling for the `select_multi`, `bodily_injury`,
   * `vehicle_seatmap`, and `select_or_create` components. Eventually, these
   * exceptions probably don't need to be carved out, but that's a refactor for
   * another day.
   */
  stepReadyToContinue: boolean;

  /**
   * Allows a step to override the auto-submit behavior. If set to `true`, the
   * step will attempt to auto-submit when the user has entered all required
   * fields. If set to `false`, the step will not auto-submit. If set to
   * `null`, the step will use the step-configured or default behavior.
   */
  setAutoSubmitOverride: (override: boolean | null) => void;
};

export const StepContext = createContext<StepCtx | null>(null);

type ValuesType = Record<string, any>;

/**
 * Provides a StepContext for a step rendering stack (`StepRenderer` and
 * `StepComponentRenderer`, as well as `StepComponentFC` implementations).
 */
export const StepProvider: React.FC<{
  step: ClaimWorkflowStepFragmentData;
  registry: StepComponentRegistry;
  onSubmit: (values: Record<string, any>) => void;
  values?: Record<string, any>;
  autoSubmit?: boolean;
  documentComponentValidationLogic: DocumentComponentValidationLogic;
}> = ({
  autoSubmit,
  children,
  documentComponentValidationLogic,
  onSubmit,
  registry,
  step,
  values: initialValues,
}) => {
  const [values, setValues] = useState<ValuesType>(initialValues || {});
  const [isDirty, setIsDirty] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [errorCount, setErrorCount] = useState<number>(0);
  const [skipConfirmed, setSkipConfirmed_] = useState(false);
  const [autoSubmitOverride, setAutoSubmitOverride] = useState<boolean | null>(
    null,
  );
  const ldClient = useLDClient();
  const enableFcContinueMetadata = ldClient?.variation(
    'fc-continue-button-metadata',
    false,
  );
  useEffect(() => {
    // autoSubmitOverride should only apply to the next submit
    setAutoSubmitOverride(null);
  }, [step.key]);

  useEffect(() => {
    if (
      step.content.skip_require_confirmation_checkbox &&
      step.content.skip_initially_confirmed
    ) {
      setSkipConfirmed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step.content.skip_initially_confirmed]);

  const updateValue = useCallback((k: string | undefined, v: any) => {
    setIsDirty(true);
    if (k) {
      setValues(values => ({ ...values, [k]: v }));
    }
  }, []);

  const submit = useCallback(
    (valuesToSubmit: ValuesType, { force }: { force?: boolean } = {}) => {
      setErrorCount(0);
      setErrors({});
      // No validation
      if (force) {
        onSubmit(valuesToSubmit);
        return;
      }

      setTimeout(async () => {
        const errs = await _validateSchemaAndCollectErrors({
          valuesToSubmit,
          step,
          registry,
          documentComponentValidationLogic,
        });

        if (!errs) {
          onSubmit(valuesToSubmit);
          return;
        }

        setErrors(errs);
        setErrorCount(errorCount + 1);
      }, 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [step, onSubmit, errorCount],
  );

  // for automation, such as runClaimbotScript
  (window as any).stepSubmit = submit;
  (window as any).step = step;

  const attemptSubmit = useCallback(() => {
    const hasFields = step.content.step_components.some(c => !!c.field);
    const allEntered = step.content.step_components[
      step.content.autosubmit_partial ? 'some' : 'every'
    ](c => (c.field ? typeof values[c.field] !== 'undefined' : true));

    if (allEntered && hasFields) {
      submit(values);
    }
  }, [step, values, submit]);

  const resetWithValues = useCallback(
    (values: Record<string, any>) => {
      setValues(values);
      setErrors({});
      setErrorCount(0);
      setSkipConfirmed(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValues, setErrors, setErrorCount],
  );

  const skip = useCallback(() => {
    let continueAnswer: string | undefined;
    if (enableFcContinueMetadata) {
      continueAnswer = JSON.stringify({
        moveForwardSelection: step.content.skip_label || 'Skip',
      });
    }

    const metadata = continueAnswer
      ? { [continueAnswer]: step.content.skip_label || 'Skip' }
      : {};

    submit(
      step.content.skip_field
        ? {
            [step.content.skip_field]: step.content.skip_value,
            ...metadata,
          }
        : { ...metadata },
      { force: true },
    );
  }, [submit, step, enableFcContinueMetadata, values]);

  const setSkipConfirmed = useCallback((confirmed: boolean) => {
    setSkipConfirmed_(confirmed);

    if (confirmed) {
      setValues({});
    }
  }, []);

  const stepSkippable = !!step.content.skip_label;
  const skipNeedsManualConfirm =
    stepSkippable && !!step.content.skip_require_confirmation_checkbox;

  /*
    1. ManualSubmitRequired will cause Step to:
      - not auto-submit
      - continue button to be present
      - User will need to click "Continue" to submit the step
    2. To resubmit a step without the Continue button:
      - must be a single component
      - step component has `no_continue_button_to_resubmit` property set to true
      -`eng-9471-steps-with-no-continue-button` FF on
  */

  let manualSubmitRequired = _getIsManualSubmitRequired({
    step,
    registry,
  });

  useEffect(() => {
    if (
      (autoSubmitOverride === null ? autoSubmit : autoSubmitOverride) &&
      !manualSubmitRequired
    ) {
      attemptSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, autoSubmitOverride]);

  if (
    step.content.step_components.some(c =>
      stepComponentHasConfigFlag(c, 'hideManualSubmit', registry),
    )
  ) {
    manualSubmitRequired = false;
  }

  const stepRequiresFullPageHeight = step.content.step_components.some(c =>
    stepComponentHasConfigFlag(c, 'fullPageHeight', registry),
  );

  const stepControlledTitle = step.content.step_components.some(c =>
    stepComponentHasConfigFlag(c, 'controlsTitle', registry),
  );

  const stepReadyToContinue = _getIsStepReadyToContinue({
    step,
    values,
    documentComponentValidationLogic,
  });

  const valuesJSON = _captureNestedFields(values);

  const memoizedData = useMemo(
    () => ({
      step,
      valuesJSON,
      registry,
      values,
      errors,
      errorCount,
      updateValue,
      submit,
      attemptSubmit,
      skip,
      stepSkippable,
      isDirty,
      skipNeedsManualConfirm,
      skipConfirmed,
      setSkipConfirmed,
      resetWithValues,
      manualSubmitRequired,
      stepControlledTitle,
      stepRequiresFullPageHeight,
      stepReadyToContinue,
      setAutoSubmitOverride,
    }),
    [
      step,
      valuesJSON,
      registry,
      values,
      errors,
      errorCount,
      updateValue,
      submit,
      attemptSubmit,
      skip,
      stepSkippable,
      isDirty,
      skipNeedsManualConfirm,
      skipConfirmed,
      setSkipConfirmed,
      resetWithValues,
      manualSubmitRequired,
      stepControlledTitle,
      stepRequiresFullPageHeight,
      stepReadyToContinue,
      setAutoSubmitOverride,
    ],
  );

  return (
    <StepContext.Provider value={memoizedData}>{children}</StepContext.Provider>
  );
};

/**
 * This hook is used to access the `StepContext` from the step rendering component
 * stack, as well as step components themselves.
 */
export const useStep = () => {
  const ctx = useContext(StepContext);
  if (!ctx) {
    throw new Error('No StepContext found');
  }
  return ctx;
};

// TODO: move functions exported for testing to a different file

export function _mapFields(obj: any, value: any, result: any) {
  if (typeof obj === 'object' && obj !== null) {
    if ('field' in obj) {
      // If the object has a 'field' property, add its value to the result
      result[obj.field] = value?.[obj.field] || value;
    } else {
      // Otherwise, recursively call mapFields for each property
      for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          _mapFields(obj[prop], value, result);
        }
      }
    }
  }
}

/**
 If the values key is a stringified JSON object, it's a nested field
 Transform nested field entries
 from:{"{'relation':{'index':0,'name':'hitAndRunParties','fields':[{'field':'type'}]}}": "Other"}
 to: { "type": "OTHER" }
 Keys are not always nested field though, in those cases captureNestedFields just mirrors what is in 'values'
 Current use-case for parsing nested keys is for client-side filtering
 */
export function _captureNestedFields(values: ValuesType) {
  const result: ValuesType = {};

  for (const key in values) {
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      try {
        const parsedKey = JSON.parse(key);
        _mapFields(parsedKey, values[key], result);
      } catch (error) {
        result[key] = values[key];
      }
    }
  }

  return result;
}

export const _extractInnerPath = (innerE: any): string => {
  if (innerE.path.startsWith('[')) {
    return unescapeComponentJSONFieldName(JSON.parse(innerE.path)[0]);
  }

  // if we are on seat selection and email is wrong format
  // we will get the type seat-selection-email-validation
  if (innerE.type && innerE.type === 'seat-selection-email-validation') {
    return innerE.type;
  }

  return unescapeComponentJSONFieldName(innerE.path);
};

interface GetIsStepValidParams {
  step: ClaimWorkflowStepFragmentData;
  values: ValuesType;
}

export const _getIsValidStep = ({ step, values }: GetIsStepValidParams) => {
  const stepValidationSchema = generateValidationSchemaForStep({
    stepContent: step.content,
  });

  let isValidStep = false;

  try {
    stepValidationSchema.parse(values);
    isValidStep = true;
  } catch (e) {
    isValidStep = false;
  }

  return isValidStep;
};

interface GetIsStepReadyToContinueParams {
  step: ClaimWorkflowStepFragmentData;
  values: ValuesType;
  documentComponentValidationLogic: DocumentComponentValidationLogic;
}

export const _getIsStepReadyToContinue = ({
  step,
  values,
  documentComponentValidationLogic,
}: GetIsStepReadyToContinueParams) => {
  const isValidStep = _getIsValidStep({ step, values });

  const isReady =
    isValidStep &&
    !(
      step.content.require_one_of && Object.keys(values).every(k => !values[k])
    ) &&
    step.content.step_components.some(c => {
      return (
        !(
          c.type === 'string' &&
          c.mode === 'small_number' &&
          c.field &&
          values[c.field] === 0 &&
          c.required
        ) &&
        !(
          c.type === 'select_multi' &&
          c.field &&
          c.required &&
          !values[c.field]?.length
        ) &&
        !(
          (c.type === 'bodily_injury' ||
            (c.type === 'vehicle_seatmap' && c.single) ||
            c.type === 'select_or_create') &&
          c.field &&
          !values[c.field]
        ) &&
        !(
          (c.type === 'party_adder' ||
            c.type === 'vehicle_damage_region_picker' ||
            c.type === 'datetime_without_timezone' ||
            c.type === 'license_plate_selector' ||
            c.type === 'vehicle_occupant_entry_wizard' ||
            c.type === 'location' ||
            c.type === 'vehicle_speed') &&
          c.field &&
          !generateValidationSchema({
            documentComponentValidationLogic,
            step,
          }).isValidSync(values)
        ) &&
        !(
          c.type === 'underwriting_memo_address' &&
          c.field &&
          !underwritingMemoAddressIsReady(values[c.field])
        ) &&
        !(
          c.type === 'string' &&
          c.mode === 'distance_number' &&
          c.field &&
          values[c.field] === 0
        )
      );
    });

  return isReady;
};

export const _getEscapedValuesToSubmit = (valuesToSubmit: ValuesType) => {
  const escapedValuesToSubmit = Object.keys(valuesToSubmit).reduce(
    (acc, key) => {
      acc[escapeComponentJSONFieldName(key)] = valuesToSubmit[key];
      return acc;
    },
    {} as Record<string, any>,
  );

  return escapedValuesToSubmit;
};

interface CollectValidationErrorsParams {
  validationErrors: ValidationError[];
  step: ClaimWorkflowStepFragmentData;
  registry: StepComponentRegistry;
}

export const _collectValidationErrors = ({
  validationErrors,
  step,
  registry,
}: CollectValidationErrorsParams) => {
  const errs: Record<string, string> = {};

  for (const innerE of validationErrors) {
    const innerPath = _extractInnerPath(innerE);
    errs[innerPath] = Array.isArray(innerE.errors)
      ? innerE.errors.join('; ')
      : innerE.errors;
  }

  const uncapturedErrors = [];

  for (const escapedPath in errs) {
    if (Object.prototype.hasOwnProperty.call(errs, escapedPath)) {
      const path = unescapeComponentJSONFieldName(escapedPath);
      const c = step.content.step_components.find(com => {
        return (
          (com.field && path.startsWith(com.field)) ||
          ('other_field' in com &&
            com.other_field &&
            path.startsWith(com.other_field))
        );
      });

      if (c && !stepComponentHasConfigFlag(c, 'controlsError', registry)) {
        uncapturedErrors.push(errs[path]);
      }
    }
  }

  if (uncapturedErrors.length) {
    errs.default = uncapturedErrors
      .filter((e, i, arr) => arr.indexOf(e) === i)
      .join('; ');
  }

  return errs;
};

interface GetIsManualSubmitRequiredParams {
  step: ClaimWorkflowStepFragmentData;
  registry: StepComponentRegistry;
}

export const _getIsManualSubmitRequired = ({
  step,
  registry,
}: GetIsManualSubmitRequiredParams) => {
  const isManualSubmitRequired =
    step.content.manual_submit_label ||
    step.content.step_components.some(
      c =>
        stepComponentHasConfigFlag(c, 'manualSubmit', registry) ||
        c.type === 'vehicle_damage_picker',
    ) ||
    step.content.step_components.every(
      c => c.type === 'interstitial' || c.type === 'scripting_instructions',
    ) ||
    step.content.step_components.some(
      // Since Select & Grouped Searchable Select by default auto-submits once selected, if there's an existing value, require "Continue" click.
      c =>
        (c.type === 'select' || c.type === 'grouped_searchable_select') &&
        typeof c.existing_value !== 'undefined',
    );

  return isManualSubmitRequired;
};

interface ValidateSchemaParams {
  valuesToSubmit: ValuesType;
  documentComponentValidationLogic: DocumentComponentValidationLogic;
  step: ClaimWorkflowStepFragmentData;
}

export const _validateSchema = ({
  valuesToSubmit,
  documentComponentValidationLogic,
  step,
}: ValidateSchemaParams) => {
  const escapedValuesToSubmit = _getEscapedValuesToSubmit(valuesToSubmit);

  const schema = generateValidationSchema({
    documentComponentValidationLogic,
    step,
  });

  return schema.validate(escapedValuesToSubmit, { abortEarly: false });
};

interface ValidateSchemaAndCollectErrors {
  valuesToSubmit: ValuesType;
  documentComponentValidationLogic: DocumentComponentValidationLogic;
  step: ClaimWorkflowStepFragmentData;
  registry: StepComponentRegistry;
}

export const _validateSchemaAndCollectErrors = async ({
  valuesToSubmit,
  documentComponentValidationLogic,
  step,
  registry,
}: ValidateSchemaAndCollectErrors) => {
  try {
    await _validateSchema({
      valuesToSubmit,
      documentComponentValidationLogic,
      step,
    });

    return undefined;
  } catch (e: unknown) {
    let errs: Record<string, string> = {};

    if ((e as ValidationError).inner) {
      errs = _collectValidationErrors({
        validationErrors: (e as ValidationError).inner,
        step,
        registry,
      });
    } else {
      errs['default'] = 'Failed to save. Please try again, or contact us.';
    }

    return errs;
  }
};
