export const formatPhoneNumber = (phoneNumberString: string): string => {
  if (!phoneNumberString) {
    return '';
  }
  const cleaned = phoneNumberString.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`;
  }
  return phoneNumberString;
};

/**
 * Phone number as International (E.164) format: `+19999999999`
 * (See https://en.wikipedia.org/wiki/E.164)
 *
 * If number doesn't already start with `+`, assume it's a
 * US number and prefix it with `+1`.
 */
export function internationalPhoneNumberFormat(
  phoneNumber: string | undefined,
) {
  const trimmed = phoneNumber?.trim() ?? '';
  const withoutPlus = trimmed.startsWith('+') ? trimmed.slice(1) : trimmed;
  let cleaned = withoutPlus
    .replace(/[^0-9]/g, '')
    // 15 digits max
    .slice(0, 15);

  // if the number is 10 or less digits and doesn't start with 1,
  // assume it's a US number and prefix with 1
  if (!cleaned.startsWith('1') && cleaned.length <= 10) {
    cleaned = `1${cleaned}`;
  }

  return `+${cleaned}`;
}

/**
 * Phone number as NANP format: `(999) 999-9999`
 * (See https://en.wikipedia.org/wiki/North_American_Numbering_Plan)
 */
export function northAmericanPhoneNumberFormat(
  phoneNumber: string | undefined,
) {
  const normalized = internationalPhoneNumberFormat(phoneNumber || '');
  const areaCode = normalized.slice(2, 5).padEnd(3, ' ');
  const prefix = normalized.slice(5, 8).padEnd(3, ' ');
  const suffix = normalized.slice(8).padEnd(4, ' ');
  const formatted = `(${areaCode}) ${prefix}-${suffix}`;
  return formatted;
}
