import classNames from 'classnames';
import { DateTime, Interval } from 'luxon';

import { IconFlatCalendar } from '../Icon/IconFlatCalendar';
import { IconFlatChevronOutlineLeft } from '../Icon/IconFlatChevronOutlineLeft';
import { IconFlatChevronOutlineRight } from '../Icon/IconFlatChevronOutlineRight';
import { sameDay } from './reducer';

const luxonSort = (a: DateTime, b: DateTime) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

const formatWeekInterval = (interval: Interval) => {
  const { start, end } = interval;

  if (!start) {
    return '';
  }
  if (!end) {
    return '';
  }

  if (!start.hasSame(end, 'year')) {
    return `${start.toFormat('MMM d yyyy')} - ${end.toFormat('MMM d yyyy')}`;
  }

  if (!start.hasSame(end, 'month')) {
    return `${start.toFormat('MMM d')} - ${end.toFormat('MMM d yyyy')}`;
  }

  return `${start.toFormat('MMMM d')} - ${end.toFormat('d yyyy')}`;
};

const dayTwoLetter = (day: string) => {
  // eslint-disable-next-line default-case
  switch (day) {
    case 'Monday':
      return 'Mo';
    case 'Tuesday':
      return 'Tu';
    case 'Wednesday':
      return 'We';
    case 'Thursday':
      return 'Th';
    case 'Friday':
      return 'Fr';
    case 'Saturday':
      return 'Sa';
    case 'Sunday':
      return 'Su';
  }
  return day;
};

const SEVEN_DAYS = Array.from({ length: 7 });

type Props = {
  currentWeek: DateTime<boolean>;
  selectedDate: DateTime<boolean> | null;
  referenceDate: DateTime;
  appointmentSlots: DateTime[];
  warningDates?: DateTime[];
  onPrevWeek: () => void;
  onNextWeek: () => void;
  onSelectDate: (newDate: DateTime<boolean>) => void;
};

export const DateInWeekSelectorControlled: React.FC<Props> = ({
  currentWeek,
  selectedDate,
  referenceDate,
  appointmentSlots,
  warningDates,
  onPrevWeek,
  onNextWeek,
  onSelectDate,
}) => {
  const currentInterval = Interval.fromDateTimes(
    currentWeek,
    currentWeek.plus({ days: 6 }),
  );
  const canScrollBack = !currentInterval.contains(referenceDate);
  const canScrollForward =
    currentWeek.plus({ weeks: 1 }) < referenceDate.plus({ days: 30 });
  const sortedSlots = appointmentSlots.sort(luxonSort);

  return (
    <>
      <div className="flex items-center gap-2 w-full">
        <IconFlatCalendar className="[&_path]:fill-gray-600" />
        <span className="text-gray-600 font-medium text-md">
          {formatWeekInterval(currentInterval)}
        </span>
        <div className="grow shrink" />
        <div className="flex items-center gap-2">
          <button
            className={classNames('p-1 rounded-md', {
              'hover:bg-indigo-bright-50': canScrollBack,
            })}
            type="button"
            onClick={onPrevWeek}
            disabled={!canScrollBack}
          >
            <IconFlatChevronOutlineLeft
              className={classNames({
                '[&_path]:stroke-gray-300': !canScrollBack,
                '[&_path]:stroke-gray-700': canScrollBack,
              })}
            />
          </button>
          <button
            className={classNames('p-1 rounded-sm', {
              'hover:bg-indigo-bright-50': canScrollForward,
            })}
            type="button"
            onClick={onNextWeek}
            disabled={!canScrollForward}
          >
            <IconFlatChevronOutlineRight
              className={classNames({
                '[&_path]:stroke-gray-300': !canScrollForward,
                '[&_path]:stroke-gray-700': canScrollForward,
              })}
            />
          </button>
        </div>
      </div>
      <div className="w-full">
        <ol className="flex w-full justify-between">
          {SEVEN_DAYS.map((_, index) => {
            const thisDate = currentWeek.plus({ days: index }).startOf('day');
            const isBeforeReference =
              Math.ceil(thisDate.diff(referenceDate, 'day').days) < 0;
            const firstAppointment = sortedSlots.find(potential =>
              sameDay(potential, thisDate),
            );
            const isWarningDate =
              warningDates &&
              warningDates.some(warningDate => sameDay(thisDate, warningDate));

            const status = (() => {
              if (selectedDate && sameDay(thisDate, selectedDate))
                return 'selected';
              if (isBeforeReference) return 'before';
              if (firstAppointment) return 'available';
              return 'unavailable';
            })();

            return (
              <li
                key={thisDate.toMillis()}
                className="flex flex-col items-center gap-1"
              >
                <span className="text-gray-400 text-xs font-medium">
                  {dayTwoLetter(
                    thisDate
                      .setLocale('en-US')
                      .toLocaleString({ weekday: 'long' }),
                  )}
                </span>
                <button
                  type="button"
                  disabled={status !== 'available'}
                  className={classNames(
                    'rounded-full border w-9 h-9 text-center text-xs',
                    {
                      'border-transparent': status !== 'available',
                      'bg-transparent': status !== 'selected',
                      'text-progressive-100 border-progressive-100 hover:bg-indigo-bright-50':
                        status === 'available' && !isWarningDate,
                      'text-orange-500 border-orange-500 hover:bg-orange-50':
                        status === 'available' && isWarningDate,
                      'text-white bg-progressive-100 border-progressive-100':
                        status === 'selected' && !isWarningDate,
                      'text-white bg-orange-500 border-orange-500':
                        status === 'selected' && isWarningDate,
                      'text-gray-300': status === 'unavailable',
                      'text-gray-800': status === 'before',
                    },
                  )}
                  onClick={() => onSelectDate(thisDate)}
                >
                  {thisDate
                    .setLocale('en-US')
                    .toLocaleString({ day: 'numeric' })}
                </button>
              </li>
            );
          })}
        </ol>
      </div>
    </>
  );
};
