import classNames from 'classnames';
import { DateTime, Interval } from 'luxon';
import React, { useEffect } from 'react';

import { Button } from '../Button';
import reducer, { sameDay, SimpleAction } from './reducer';
import { DateInWeekSelectorControlled } from './DateInWeekSelectorControlled';

const luxonSort = (a: DateTime, b: DateTime) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export interface Props {
  referenceDate: DateTime;
  appointmentSlots: DateTime[];
  onChangeSelectedAppointmentSlot: (date: DateTime | null) => void;
  setConfirmEnabled: (enabled: boolean) => void;
  formatAppointmentSlot?: (date: DateTime) => string;

  /**
   * Optionally, allows the parent component to control the selected appointment slots.
   * If not specified, the component will manage its own state. In either case, the
   * selection of a slot will trigger the `onChangeSelectedAppointmentSlot` callback.
   */
  selectedAppointmentSlots?: DateTime[];

  /**
   * Optionally, make the UI more compact by hiding the intermediate header.
   */
  hideAvailabilityHeader?: boolean;
}

const timeslotButtonClasses =
  'px-[10px] py-[6px] text-xs [&>span]:whitespace-nowrap [&>span]:p-0 [&>span]:w-[64px] [&>span]:h-[17px]';

const AppointmentSelector: React.FC<Props> = ({
  referenceDate,
  appointmentSlots,
  onChangeSelectedAppointmentSlot,
  setConfirmEnabled,
  formatAppointmentSlot,
  selectedAppointmentSlots,
  hideAvailabilityHeader,
}) => {
  const isControlled = typeof selectedAppointmentSlots !== 'undefined';
  const sortedSlots = appointmentSlots.sort(luxonSort);
  const firstDate = sortedSlots[0] ?? referenceDate;
  const [{ currentWeek, showTimeSlotsFor, selectedTimeSlot }, dispatch] =
    React.useReducer(reducer, {
      currentWeek: firstDate.startOf('week').minus({ days: 1 }), // Luxon week starts on Monday by default and can't be configured or changed
      showTimeSlotsFor: firstDate,
      selectedTimeSlot: {
        slot: null,
        selectedBy: 'system' as const,
      },
    });

  useEffect(() => {
    // If the component is controlled (supports multiple slots being selected), system auto-selections are
    // ignored as we are only interested in user manual selections.
    if (isControlled && selectedTimeSlot?.selectedBy === 'system') {
      return;
    }

    onChangeSelectedAppointmentSlot(selectedTimeSlot?.slot ?? null);
  }, [selectedTimeSlot]);

  const dispatchWithCallback = (action: SimpleAction) => {
    dispatch({
      ...action,
      callback: () => {},
    });
  };

  const currentInterval = Interval.fromDateTimes(
    currentWeek,
    currentWeek.plus({ days: 6 }),
  );

  const selectedDaysTimeSlots = showTimeSlotsFor
    ? sortedSlots.filter(appointmentSlot => {
        return sameDay(showTimeSlotsFor, appointmentSlot);
      })
    : [];

  const [seeAllTimeSlots, setSeeAllTimeSlots] = React.useState(false);

  const visibleTimeSlots = (() => {
    if (!showTimeSlotsFor) return [];
    if (!currentInterval.contains(showTimeSlotsFor)) return [];
    if (seeAllTimeSlots) return selectedDaysTimeSlots;
    if (selectedDaysTimeSlots.length <= 6) return selectedDaysTimeSlots;
    return selectedDaysTimeSlots.slice(0, 5); // remove one extra to make space for the "view more" btn
  })();

  useEffect(() => {
    setConfirmEnabled((visibleTimeSlots?.length ?? 0) < 1);
  }, []);

  const isShowingAllTimeSlots =
    seeAllTimeSlots || selectedDaysTimeSlots.length > 6;

  return (
    <div className="w-full flex flex-col gap-6">
      <DateInWeekSelectorControlled
        currentWeek={currentWeek}
        selectedDate={showTimeSlotsFor}
        referenceDate={referenceDate}
        appointmentSlots={appointmentSlots}
        onPrevWeek={() => {
          setSeeAllTimeSlots(false); // collapse extended view when switching day or week
          setConfirmEnabled(false); // disable Confirm button when switching week
          dispatchWithCallback({
            type: 'PREV_WEEK',
            payload: { available: sortedSlots },
          });
        }}
        onNextWeek={() => {
          setSeeAllTimeSlots(false); // collapse extended view when switching day or week
          setConfirmEnabled(false); // disable Confirm button when switching week
          dispatchWithCallback({
            type: 'NEXT_WEEK',
            payload: { available: sortedSlots },
          });
        }}
        onSelectDate={(newDate: DateTime<boolean>) => {
          setSeeAllTimeSlots(false); // collapse extended view when switching day or week
          dispatchWithCallback({
            type: 'SELECT_DAY',
            payload: { selected: newDate, available: sortedSlots },
          });
        }}
      />
      <div className="w-full">
        {!hideAvailabilityHeader && (
          <div className="w-full mb-4 ml-1 font-medium text-md text-gray-600 text-justify justify-start">
            Availability
          </div>
        )}
        {visibleTimeSlots.length === 0 ? (
          <div className="w-full flex justify-center">
            <span className="text-gray-400 text-sm">
              No available appointments this week
            </span>
          </div>
        ) : (
          <div className="w-full">
            <ol className="w-full justify-items-center justify-center grid grid-cols-3 gap-4">
              {visibleTimeSlots.map(slot => {
                const isSelected = isControlled
                  ? selectedAppointmentSlots.some(s => s === slot)
                  : selectedTimeSlot.slot?.toMillis() === slot.toMillis();
                return (
                  <li key={slot.toMillis()} className="w-[95px]">
                    <Button
                      size="sm"
                      variant={isSelected ? 'primary' : 'secondary'}
                      className={classNames(timeslotButtonClasses, {
                        'border-2 border-progressive-100 bg-progressive-100 disabled:cursor-default':
                          isSelected,
                        'border-2 !border-blue-100 text-progressive-100 ':
                          !isSelected,
                        'hover:bg-progressive-100': isSelected && isControlled,
                      })}
                      onClick={() => {
                        setConfirmEnabled(true);
                        dispatchWithCallback({
                          type: 'SELECT_TIMESLOT',
                          payload: { selected: slot },
                        });
                      }}
                      disabled={isSelected && !isControlled}
                    >
                      {formatAppointmentSlot
                        ? formatAppointmentSlot(slot)
                        : slot.toFormat('t')}
                    </Button>
                  </li>
                );
              })}
              {isShowingAllTimeSlots && !seeAllTimeSlots && (
                <li className="w-[95px]">
                  <Button
                    size="sm"
                    variant="secondary"
                    className={classNames(
                      timeslotButtonClasses,
                      'border-transparent text-progressive-100',
                    )}
                    onClick={() => setSeeAllTimeSlots(true)}
                  >
                    View more
                  </Button>
                </li>
              )}
            </ol>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppointmentSelector;
