/* eslint-disable @typescript-eslint/no-explicit-any, no-prototype-builtins, no-param-reassign */
export const anonymizeClaim = (claim: Record<string, any>) => {
  // Helper function to deeply clone an object to avoid mutating the original object
  const deepClone = (obj: Record<string, any>) =>
    JSON.parse(JSON.stringify(obj));

  const usedAnonymousStrings: string[] = [];
  // function to create an anonymous string that
  // is not inc the usedAnonymousStrings array
  // of the same length as the passed in string
  const generateUniqueRandomString = (input: string, isEmail?: boolean) => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    function getRandomString(length: number) {
      return Array.from(
        { length },
        () => characters[Math.floor(Math.random() * characters.length)],
      ).join('');
    }

    let randomString;
    do {
      randomString = getRandomString(input.length);
    } while (usedAnonymousStrings.includes(randomString));

    usedAnonymousStrings.push(randomString);
    return isEmail ? `${randomString}@email.com` : randomString;
  };
  // Define keys that should have their string values replaced with "REDACTED"
  const anonymize: Record<string, Record<string, string>> = {
    firstName: {},
    lastName: {},
    middleName: {},
    phoneNumber: {},
    addressText: {},
    line1: {},
    line2: {},
    city: {},
    name: {},
    insuredName: {},
    email: {},
  };
  // Helper function to redact information
  const anonymizeInfo = (obj: Record<string, any>) => {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        anonymizeInfo(obj[key]); // Recurse into sub-objects
      } else {
        const anonymizeKeys = Object.keys(anonymize);
        // Special case keys
        const specialCaseKeys: any = {
          latitude: () => 0.0,
          longitude: () => 0.0,
          licensePlate: () => 'ABCD000',
          vin: (v: string | undefined) =>
            v?.length === 17 ? 'ABCD0000000000000' : 'REDACTED',
        };
        const isEmailKey = key.toLowerCase().includes('email');
        if (
          (anonymizeKeys.includes(key) || isEmailKey) &&
          obj[key] !== null &&
          obj[key] !== ''
        ) {
          const anonymizedKey = isEmailKey ? 'email' : key;
          const val = obj[key].toLowerCase().trim();
          if (anonymize[anonymizedKey].hasOwnProperty(val)) {
            obj[key] = anonymize[anonymizedKey][val];
          } else {
            const anonymizedVal = generateUniqueRandomString(val, isEmailKey);
            anonymize[anonymizedKey][val] = anonymizedVal;
            obj[key] = anonymizedVal;
          }
        }
        if (key in specialCaseKeys) {
          obj[key] = specialCaseKeys[key](obj[key]);
        }
      }
    }
  };

  // Clone the object to avoid modifying the original
  const clonedObj = deepClone(claim);
  // Redact the cloned object
  anonymizeInfo(clonedObj);

  return clonedObj;
};
