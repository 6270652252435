import { DetailedHTMLProps } from 'react';

type HtmlSvgProps = DetailedHTMLProps<
  React.SVGAttributes<SVGSVGElement>,
  SVGSVGElement
>;

export const IconEmojiSemiTrailer = (props: HtmlSvgProps) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.78125 24.1875H3.9375V32.625H3.09375V32.9062H3.9375H4.78125H5.625V32.625H4.78125V24.1875Z"
        fill="url(#paint0_linear_719_495)"
      />
      <path
        d="M4.78125 29.3906L7.59375 23.9062"
        stroke="url(#paint1_linear_719_495)"
      />
      <path
        d="M3.9375 29.3906L1.125 23.9062"
        stroke="url(#paint2_linear_719_495)"
      />
      <rect
        x="3.65625"
        y="28.9688"
        width="1.40625"
        height="0.84375"
        fill="url(#paint3_linear_719_495)"
      />
      <rect
        x="3.65625"
        y="24.1875"
        width="1.40625"
        height="0.84375"
        fill="url(#paint4_linear_719_495)"
      />
      <rect
        x="1.125"
        y="24.1875"
        width="1.125"
        height="0.5625"
        fill="url(#paint5_linear_719_495)"
      />
      <rect
        x="6.46875"
        y="24.1875"
        width="1.125"
        height="0.5625"
        fill="url(#paint6_linear_719_495)"
      />
      <rect
        x="34.3125"
        y="24.1875"
        width="0.5625"
        height="4.5"
        fill="url(#paint7_linear_719_495)"
      />
      <rect
        x="34.0312"
        y="24.1875"
        width="1.125"
        height="0.5625"
        fill="url(#paint8_linear_719_495)"
      />
      <path
        d="M0 7.03125H36V24.1875H0V7.03125Z"
        fill="url(#paint9_linear_719_495)"
      />
      <g style={{ mixBlendMode: 'overlay', opacity: 0.5 }}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.375 7.03125H3.65625V24.1875H3.375V7.03125ZM7.03125 7.03125H7.3125V24.1875H7.03125V7.03125ZM10.9688 7.03125H10.6875V24.1875H10.9688V7.03125ZM14.3438 7.03125H14.625V24.1875H14.3438V7.03125ZM18.2812 7.03125H18V24.1875H18.2812V7.03125ZM21.6562 7.03125H21.9375V24.1875H21.6562V7.03125ZM25.5938 7.03125H25.3125V24.1875H25.5938V7.03125ZM28.9688 7.03125H29.25V24.1875H28.9688V7.03125ZM32.9062 7.03125H32.625V24.1875H32.9062V7.03125Z"
          fill="black"
        />
      </g>
      <path
        d="M1 8.03125H35V23.1875H1V8.03125Z"
        stroke="url(#paint10_linear_719_495)"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5312 24.1875H10.9688V25.3125H11.5312V26.7188H10.9688V27.8438H11.5312V27.6875C11.5312 28.2398 11.979 28.6875 12.5313 28.6875H30.5C31.0523 28.6875 31.5 28.2398 31.5 27.6875V27.8438H32.0625V26.7188H31.5V25.3125H32.0625V24.1875H31.5H11.5312Z"
        fill="url(#paint11_linear_719_495)"
      />
      <rect
        opacity="0.25"
        x="11.5312"
        y="25.3125"
        width="19.9688"
        height="0.28125"
        fill="black"
      />
      <rect
        opacity="0.25"
        x="11.5312"
        y="27.8438"
        width="19.9688"
        height="0.28125"
        fill="black"
      />
      <g filter="url(#filter0_i_719_495)">
        <path
          d="M12.9375 28.8711V29.6303H21.1605V28.8711C21.1605 26.5951 19.3197 24.75 17.049 24.75C14.7783 24.75 12.9375 26.5951 12.9375 28.8711Z"
          fill="url(#paint12_linear_719_495)"
        />
      </g>
      <circle
        cx="17.0489"
        cy="29.0399"
        r="3.77522"
        fill="url(#paint13_radial_719_495)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.049 31.3585C18.3296 31.3585 19.3678 30.3204 19.3678 29.0397C19.3678 27.7591 18.3296 26.7209 17.049 26.7209C15.7684 26.7209 14.7302 27.7591 14.7302 29.0397C14.7302 30.3204 15.7684 31.3585 17.049 31.3585ZM17.0491 28.2043C17.2838 28.2043 17.4894 27.9218 17.6036 27.4983C17.4304 27.436 17.2437 27.402 17.049 27.402C16.8544 27.402 16.6677 27.436 16.4945 27.4983C16.6087 27.9217 16.8143 28.2043 17.0491 28.2043ZM17.7725 28.622C17.6552 28.4188 17.797 28.0996 18.1064 27.7891C18.3912 28.0301 18.5926 28.3667 18.661 28.7493C18.2373 28.8621 17.8899 28.8253 17.7725 28.622ZM17.7725 29.4574C17.8899 29.2542 18.2373 29.2174 18.661 29.3302C18.5925 29.7128 18.3912 30.0493 18.1064 30.2904C17.7969 29.9799 17.6552 29.6606 17.7725 29.4574ZM17.0491 29.8751C17.2838 29.8751 17.4894 30.1577 17.6036 30.5811C17.4304 30.6435 17.2437 30.6774 17.049 30.6774C16.8544 30.6774 16.6677 30.6435 16.4945 30.5812C16.6087 30.1577 16.8143 29.8751 17.0491 29.8751ZM16.3256 29.4575C16.4429 29.6607 16.3011 29.9799 15.9917 30.2904C15.7069 30.0494 15.5055 29.7128 15.437 29.3302C15.8607 29.2174 16.2082 29.2542 16.3256 29.4575ZM16.3256 28.622C16.2082 28.8253 15.8607 28.8621 15.437 28.7493C15.5055 28.3666 15.7068 28.0301 15.9916 27.7891C16.3011 28.0996 16.4429 28.4188 16.3256 28.622Z"
        fill="url(#paint14_linear_719_495)"
      />
      <g filter="url(#filter1_i_719_495)">
        <path
          d="M21.9375 28.8712V29.6304H29.4345H30.1606V28.8712C30.1606 26.5951 28.3198 24.75 26.049 24.75C23.7783 24.75 21.9375 26.5951 21.9375 28.8712Z"
          fill="url(#paint15_linear_719_495)"
        />
      </g>
      <circle
        cx="26.0492"
        cy="29.0399"
        r="3.77526"
        fill="url(#paint16_radial_719_495)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9658 31.3585C27.2464 31.3585 28.2845 30.3204 28.2845 29.0397C28.2845 27.7591 27.2464 26.7209 25.9658 26.7209C24.6851 26.7209 23.647 27.7591 23.647 29.0397C23.647 30.3204 24.6851 31.3585 25.9658 31.3585ZM25.9658 28.2043C26.2005 28.2043 26.4062 27.9218 26.5203 27.4983C26.3471 27.436 26.1604 27.402 25.9658 27.402C25.7711 27.402 25.5844 27.436 25.4113 27.4983C25.5254 27.9217 25.7311 28.2043 25.9658 28.2043ZM26.6893 28.622C26.5719 28.4188 26.7137 28.0996 27.0232 27.7891C27.3079 28.0301 27.5093 28.3667 27.5778 28.7493C27.1541 28.8621 26.8066 28.8253 26.6893 28.622ZM26.6893 29.4574C26.8066 29.2542 27.1541 29.2174 27.5778 29.3302C27.5093 29.7128 27.3079 30.0493 27.0231 30.2904C26.7137 29.9799 26.5719 29.6606 26.6893 29.4574ZM25.9658 29.8751C26.2005 29.8751 26.4062 30.1577 26.5203 30.5811C26.3471 30.6435 26.1604 30.6774 25.9658 30.6774C25.7711 30.6774 25.5844 30.6435 25.4113 30.5812C25.5254 30.1577 25.7311 29.8751 25.9658 29.8751ZM25.2423 29.4575C25.3596 29.6607 25.2179 29.9799 24.9084 30.2904C24.6236 30.0494 24.4222 29.7128 24.3537 29.3302C24.7775 29.2174 25.125 29.2542 25.2423 29.4575ZM25.2423 28.622C25.125 28.8253 24.7775 28.8621 24.3537 28.7493C24.4222 28.3666 24.6236 28.0301 24.9084 27.7891C25.2179 28.0996 25.3596 28.4188 25.2423 28.622Z"
        fill="url(#paint17_linear_719_495)"
      />
      <defs>
        <filter
          id="filter0_i_719_495"
          x="12.9375"
          y="24.75"
          width="8.2229"
          height="5.88037"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_719_495"
          />
        </filter>
        <filter
          id="filter1_i_719_495"
          x="21.9375"
          y="24.75"
          width="8.22314"
          height="5.88037"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_719_495"
          />
        </filter>
        <linearGradient
          id="paint0_linear_719_495"
          x1="4.21875"
          y1="32.9063"
          x2="4.21875"
          y2="25.3284"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_719_495"
          x1="6.46875"
          y1="23.9062"
          x2="6.46875"
          y2="29.3906"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_719_495"
          x1="2.8125"
          y1="23.9062"
          x2="2.8125"
          y2="29.3906"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_719_495"
          x1="4.28125"
          y1="29.8125"
          x2="4.28125"
          y2="29.0792"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_719_495"
          x1="4.28125"
          y1="25.0313"
          x2="4.28125"
          y2="24.2979"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_719_495"
          x1="1.625"
          y1="24.75"
          x2="1.625"
          y2="24.2611"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_719_495"
          x1="6.96875"
          y1="24.75"
          x2="6.96875"
          y2="24.2611"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_719_495"
          x1="34.5938"
          y1="28.6875"
          x2="34.5938"
          y2="24.7763"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_719_495"
          x1="34.5312"
          y1="24.75"
          x2="34.5312"
          y2="24.2611"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_719_495"
          x1="18"
          y1="7.03125"
          x2="18"
          y2="24.1875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDEDED" />
          <stop offset="1" stopColor="#CCCCCC" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_719_495"
          x1="18"
          y1="7.03125"
          x2="18"
          y2="24.1875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDCDCD" />
          <stop offset="1" stopColor="#9F9F9F" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_719_495"
          x1="21.5156"
          y1="28.6875"
          x2="21.5156"
          y2="24.7763"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_719_495"
          x1="17.049"
          y1="25.8407"
          x2="17.049"
          y2="29.6303"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.776042" stopColor="#1D1D1D" />
          <stop offset="1" stopColor="#262626" />
        </linearGradient>
        <radialGradient
          id="paint13_radial_719_495"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.0489 29.0399) rotate(90) scale(3.77522)"
        >
          <stop offset="0.65625" stopColor="#393939" />
          <stop offset="0.786458" stopColor="#4D4D4D" />
          <stop offset="1" stopColor="#1E1E1E" />
        </radialGradient>
        <linearGradient
          id="paint14_linear_719_495"
          x1="16.9625"
          y1="26.4642"
          x2="16.9264"
          y2="31.3583"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#ACACAC" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_719_495"
          x1="26.049"
          y1="25.8407"
          x2="26.049"
          y2="29.6304"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.776042" stopColor="#1D1D1D" />
          <stop offset="1" stopColor="#262626" />
        </linearGradient>
        <radialGradient
          id="paint16_radial_719_495"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.0492 29.0399) rotate(90) scale(3.77526)"
        >
          <stop offset="0.65625" stopColor="#393939" />
          <stop offset="0.786458" stopColor="#4D4D4D" />
          <stop offset="1" stopColor="#1E1E1E" />
        </radialGradient>
        <linearGradient
          id="paint17_linear_719_495"
          x1="25.8792"
          y1="26.4642"
          x2="25.8432"
          y2="31.3583"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#ACACAC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconEmojiSemiTrailer;
