import type {
  LocationFragment,
  LocationType,
} from '@assured/shared-types/Claim/LocationType';
import type { StepComponentSpec } from '../stepComponentSpec';
import type { Coordinate } from './additional/coordinate';

export enum LocationCollectionMode {
  DEVICE_GPS = 'DEVICE_GPS',
  MEDIA_EXIF = 'MEDIA_EXIF',
  MANUAL = 'MANUAL',
  MAILING_ADDRESS = 'MAILING_ADDRESS', // Like MANUAL, but with an address-optimized API and UI
  STREET_NAME = 'STREET_NAME', // Like MANUAL, but addressText will only contain street name (used by Intersection Wizard)
  FINE = 'FINE',
  HIGHWAY_EXIT = 'HIGHWAY_EXIT', // a location transformed automatically from highway exit data
  REGION_MAP = 'REGION_MAP', // like FINE, but zoomed out
  REVERSE_IP = 'REVERSE_IP',
  EXTERNAL_CASE = 'EXTERNAL_CASE',
  CONFIRM = 'CONFIRM',
}

export enum OtherMapMarker {
  X = 'X',
}

export interface CoordinateOther extends Coordinate {
  markerIconKey?: OtherMapMarker;
}

interface LocationFields {
  existing_location?: LocationFragment | Coordinate | null | undefined;
  existing_address_text?: string;
  existing_value?: LocationFragment | Coordinate | null | undefined;
  field: string;
  no_apartment_entry?: boolean;
  no_gps_entry?: boolean;
  initial_mode?: Coordinate | string | null;
  skip_label?: string;
  label?: string;
  location_type?: 'establishment';
  expected_countries?: string[];
  address_book?: (LocationType & { label: string })[];
  search_bias?: Coordinate | LocationFragment | null;
  mode?: LocationCollectionMode | `${LocationCollectionMode}`;
  location_options?: {
    other_map_coords?: CoordinateOther[] | null;
  };
}

type LocationStepComponentSpec = StepComponentSpec<LocationFields, 'location'>;

export default LocationStepComponentSpec;
