import { PreviewableFile } from '@assured/utilities/src/uploadMediaToS3';
import { useEffect, useState } from 'react';

export const VideoPreview = ({ file }: { file: PreviewableFile }) => {
  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  useEffect(() => {
    if (file.type.startsWith('video/')) {
      const url = URL.createObjectURL(file);
      setVideoUrl(url);

      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [file]);

  return videoUrl ? (
    <video
      className="h-48 my-6 mx-auto border shadow-xl"
      key={file.name}
      src={videoUrl}
      controls
      muted
      autoPlay
      loop
    />
  ) : null;
};
