import type { GroupBase, Props as ReactSelectProps } from 'react-select';
import type {} from 'react-select/base';
import { CustomSelectValue } from '@assured/step-renderer/types/step-components/additional/customSelectValue';

import { LocationType } from '../LocationIndicator';

export interface SelectOption {
  value: string;
  label: string;
  __isNew__?: boolean;
  sublabel?: string;
}

export interface FilterOption<Option = unknown> {
  readonly label: string;
  readonly value: string;
  readonly data: Option;
}

export type SelectDropdownWithTabsOption = SelectOption & {
  locationIndicatorKey?: LocationType;
};

export type SelectGroups = GroupBase<SelectDropdownWithTabsOption> & {
  category?: string;
};

export type SelectDropdownWithTabProps = Pick<
  ReactSelectProps,
  | 'autoFocus'
  | 'closeMenuOnSelect'
  | 'components'
  | 'defaultMenuIsOpen'
  | 'menuIsOpen'
  | 'menuPlacement'
  | 'minMenuHeight'
  | 'maxMenuHeight'
  | 'openMenuOnClick'
  | 'openMenuOnFocus'
  | 'value'
> & {
  groupedOptions: SelectGroups[];
  onChange: (v: { value: CustomSelectValue } | null) => void;
};

declare module 'react-select/base' {
  export interface Props<
    Option,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Group extends GroupBase<Option>,
  > {
    // these are types for props that you can pass to the component via props.selectProps
    groupCategories?: string[];
    selectedGroup?: SelectGroups;
    selectedGroupCategory?: string;
    selectGroupCategory?: (group: string) => void;
  }
}

export function isGroupOption(
  option: SelectGroups | SelectOption,
): option is SelectGroups {
  return (option as SelectGroups).options !== undefined;
}

export function isSelectOptionsList(
  options: SelectGroups[] | SelectOption[] = [],
): options is SelectOption[] {
  return !options.length || !('options' in (options as SelectGroups[])[0]);
}
