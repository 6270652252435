export type CardinalDirection = 'North' | 'South' | 'East' | 'West' | 'Unknown';

export type HighwayDirection = CardinalDirection | 'Outer' | 'Inner'; // Outer and Inner are present in the V1 file

export type HighwayExit = {
  /**
   * Numerical ID assigned to the exit by iExit
   */
  id: string;

  /**
   * The exit's full name (e.g. "Exit 123")
   */
  name: string;

  /**
   * The exit's description (e.g. "US-98; Moffett Rd")
   */
  description: string;

  /**
   * The exit's type ID number assigned by iExit. The numbers map to the following exit types:
   *
   * 1              Exit            This is what 98% of the exits are. This is what you picture when you think of a highway exit.
   *
   * 2, 11, or 13   Rest Area	      A dedicated exit as a rest area.
   *
   * 3 or 14        Turnout	        A dedicated exit for tractor trailers to pull off the highway.
   *
   * 4 or 9         Junction        A junction with another exit-based highway. Exiting here would put you on another highway.
   *
   * 5              State Border    The beginning or end of a highway in a particular state
   *
   * 7              Toll            A toll booth stop along a highway.
   *
   * 12             Welcome Center  A special type of rest area that is located near a highway's entrance into a state.
   * 990            Not Listed      A special type of exit, indicating that the user knows the exit, but it's not included in the list
   * 991            Unknown         A special type of exit, indicating that the user doesn't know the exit
   */
  exitTypeId: number;

  /**
   * The latitude of the beginning or source of the exit ramp (as opposed to the end or destination)
   */
  latitude: string;

  /**
   * The longitude of the beginning or source of the exit ramp (as opposed to the end or destination)
   */
  longitude: string;

  /**
   * The city where the exit is located
   */
  city?: string;

  /**
   * The state where the exit is located, only in V3
   */
  state?: string;

  /**
   * The zip code where the exit is located
   */
  zip: string | number;
};

// A special exit object that represents that the matching exit is not in the list
export const ExitNotListed: HighwayExit = {
  id: 'highway-exit-not-listed',
  name: 'Exit not listed',
  description: '',
  exitTypeId: 990,
  latitude: '',
  longitude: '',
  city: '',
  zip: '',
};

// A special exit object that represents that the user doesn't know which exit it is
export const ExitUnknown: HighwayExit = {
  id: 'highway-exit-unknown',
  name: 'Exit unknown',
  description: '',
  exitTypeId: 991,
  latitude: '',
  longitude: '',
  city: '',
  zip: '',
};

export const highwayExitTypeForId = (id: number) => {
  switch (id) {
    case 1:
      return 'Exit';
    case 2:
    case 11:
    case 13:
      return 'Rest Area';
    case 3:
    case 14:
      return 'Turnout';
    case 4:
    case 9:
      return 'Junction';
    case 5:
      return 'State Border';
    case 7:
      return 'Toll';
    case 12:
      return 'Welcome Center';
    case 990: // Known, but not in the list
      return 'Not Listed';
    case 991: // Unknown
      return 'Unknown';
    default:
      console.warn(`Unknown exit type ID ${id}`);
      return 'Exit';
  }
};

export type Highway = {
  /**
   * The highway's name (e.g. "I-5")
   */
  name: string;

  /**
   * One of the highway's two directions (e.g. "North" or "East")
   */
  positiveDirection: HighwayDirection;

  /**
   * The other of the highway's two directions (e.g. "South" or "West")
   */
  negativeDirection: HighwayDirection;

  /**
   * A list of exits in the highway's positive direction
   */
  positiveExits: HighwayExit[];

  /**
   * A list of exits in the highway's negative direction
   */
  negativeExits: HighwayExit[];

  /**
   * If present, this 'highway' was chosen from a "Highway not listed" option
   */
  unknown?: boolean;
};

// A special highway object that represents a highway not in the list
export const HighwayNotListed: Highway = {
  name: 'Highway not listed',
  positiveDirection: 'Unknown',
  negativeDirection: 'Unknown',
  positiveExits: [],
  negativeExits: [],
  unknown: true,
};

export type StateWithHighways = {
  /**
   * The state's two-letter abbreviation
   */
  short_name: string;

  /**
   * The state's full name
   */
  long_name: string;

  /**
   * A map of highway names (e.g. "I-5") to highway objects
   */
  highways: Record<string, Highway>;
};
