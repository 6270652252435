import 'url-search-params-polyfill';

import { H } from 'highlight.run';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';

import * as Sentry from '@sentry/react';

import App from './App';
import { config } from './config';

smoothscroll.polyfill();

const canHover = !matchMedia('(hover: none)').matches;
if (canHover) {
  document.body.classList.add('can-hover');
}
const history = createBrowserHistory(); // pass history down to app from here so sentry has access to it

// Force redirect to another domain. This is used if a deployment goes live
// before carrier is actually ready, for example.
if (process.env.REACT_APP_FORCE_REDIRECT) {
  window.location.href = process.env.REACT_APP_FORCE_REDIRECT;
}

// Cache the initial search for later. This is used in useWorkflowTelemtry, to extract
// UTM parameters later when sending tracking events.
window.__assuredInitialSearch = window.location.search;
window.__assuredInitialHash = window.location.hash;

// On chunk load failures, forcibly reload the page. This should be pretty
// much transparent to the user, because chunks are only loaded on navigation
// events, so it just seems like a "weird" navigation event.
window.addEventListener('error', e => {
  if (
    /Loading chunk [\d]+ failed/.test(e.message) ||
    /Importing a module script failed/.test(e.message) ||
    /Failed to fetch dynamically imported module/.test(e.message) ||
    /not a valid JavaScript MIME type/.test(e.message)
  ) {
    window.location.reload();
  }
});

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
    Localize?: {
      getLanguage: () => string;
      setLanguage: (l: string) => void;
      on: (e: string, f: (d: any) => void) => void;
      off: (e: string, f: (d: any) => void) => void;
    };
  }
}

H.init(config.highlightProjectId, {
  environment: config.environment,
  version: config.commitSha,
  enableSegmentIntegration: true,
  enableStrictPrivacy: false,
  enableCanvasRecording: true,
  samplingStrategy: { canvas: 1 },
  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
  },
});

Sentry.init({
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
  ],
  tracesSampleRate: 1, // If we can afford to, lets get all the traces.
  profilesSampleRate: 0.2, // We don't need 100% of session data for performance profiling
  dsn: config.sentryDsn,
  environment: config.environment,
  release: config.commitSha,
  beforeBreadcrumb(breadcrumb) {
    if (
      ['xhr', 'fetch'].includes(breadcrumb?.category ?? '') &&
      breadcrumb?.data &&
      breadcrumb.data?.url &&
      (breadcrumb?.data?.url?.includes('pub.highlight.run') ||
        breadcrumb?.data?.url?.includes('rs.fullstory.com') ||
        breadcrumb?.data?.url?.includes('api.segment.io'))
    ) {
      return null;
    }
    return breadcrumb;
  },
});

H.getSessionURL().then(sessionUrl => {
  Sentry.setContext('highlight', {
    url: sessionUrl,
  });
});

ReactDOM.render(
  <BrowserRouter>
    <App history={history} />
  </BrowserRouter>,
  document.getElementById('root'),
);
