import { DetailedHTMLProps } from 'react';

type HtmlSvgProps = DetailedHTMLProps<
  React.SVGAttributes<SVGSVGElement>,
  SVGSVGElement
>;

export const IconEmojiBus = (props: HtmlSvgProps) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        d="M13.2947 13.9416C13.2947 13.9416 13.3734 13.4128 13.4212 13.0556C13.4691 12.7013 13.5647 11.52 14.1806 11.4497C14.7966 11.3794 22.005 11.4497 22.5759 11.4497C23.1441 11.4497 23.2875 12.0656 23.3353 12.3975C23.3831 12.7294 23.5125 14.0147 23.4619 14.0147C23.2678 14.0175 13.2947 13.9416 13.2947 13.9416Z"
        fill="#047481"
      />
      <path
        d="M11.4271 13.5844C11.4271 13.5844 11.0952 12.9431 10.859 12.8025C10.6227 12.6591 4.95552 12.2456 3.39458 14.6447C1.83364 17.0437 1.47927 21.4144 1.33302 23.3409C1.18677 25.2675 1.18677 26.5387 1.18677 26.5387L20.4158 27.3741L35.2124 26.6119L35.038 19.1025C35.0015 17.5387 34.9565 14.9428 34.1211 14.2903C33.2858 13.6378 32.7768 13.7812 31.3227 13.7447C29.8686 13.7081 11.4271 13.5844 11.4271 13.5844Z"
        fill="#16BDCA"
      />
      <path
        d="M1.19257 26.2463L35.2463 26.3729C35.2463 26.3729 35.3729 30.7801 35.3729 31.1879C35.3729 31.5957 35.2463 32.0682 34.6163 32.102C33.9863 32.1329 7.04539 32.3213 7.04539 32.3213L1.31632 32.0373C1.31632 32.0373 0.655387 31.7842 0.497887 31.2188C0.340387 30.6535 0.686325 27.6948 0.686325 27.6948L1.19257 26.2463Z"
        fill="#D2D6DC"
      />
      <path
        d="M0.486528 29.7366C0.486528 29.7366 1.36122 29.8126 1.51309 29.5651C1.80278 29.0954 1.93215 26.9241 1.70434 26.6766C1.55809 26.5163 1.05184 26.5866 1.05184 26.5866C1.05184 26.5866 0.745278 27.2532 0.60184 28.1223C0.449965 29.0532 0.486528 29.7366 0.486528 29.7366Z"
        fill="#FF8A4C"
      />
      <path
        d="M8.98321 32.324C8.98321 32.324 9.0929 31.303 8.90446 30.3918C8.71321 29.4805 8.01853 27.4583 5.16665 27.4218C2.54259 27.388 1.53571 29.4946 1.34728 30.538C1.18415 31.4409 1.32196 32.0371 1.32196 32.0371C1.32196 32.0371 2.63821 32.248 5.1104 32.3437C7.58259 32.4393 8.98321 32.324 8.98321 32.324ZM24.1201 32.2087C24.1201 32.2087 24.1567 30.4509 24.6067 29.5537C25.2282 28.3134 26.1901 27.5708 28.0154 27.5708C29.8407 27.5708 30.9657 28.6424 31.3904 29.3877C32.1217 30.6702 31.8854 32.1159 31.8854 32.1159L24.1201 32.2087Z"
        fill="#868486"
      />
      <path
        d="M1.81124 31.7307C1.83092 33.0132 2.85467 34.8638 5.08499 34.8779C7.3153 34.8919 8.47124 33.1848 8.40092 31.4354C8.33061 29.6579 6.94686 28.4007 4.95842 28.4598C3.09373 28.5104 1.78311 29.9504 1.81124 31.7307Z"
        fill="#4E433D"
      />
      <path
        d="M3.36105 31.6209C3.3723 32.2987 3.92073 33.2718 5.11886 33.2803C6.31698 33.2887 6.89636 32.4393 6.8598 31.5168C6.82042 30.5803 6.05542 29.8799 5.05136 29.894C4.05292 29.9109 3.34698 30.6843 3.36105 31.6209Z"
        fill="#B2B1B2"
      />
      <path
        d="M24.7977 31.7532C24.8174 33.0273 25.8187 34.861 28.0012 34.8751C30.1837 34.8891 31.3143 33.196 31.2468 31.4579C31.1765 29.6944 29.8237 28.4485 27.8774 28.5048C26.0549 28.5554 24.7724 29.9869 24.7977 31.7532Z"
        fill="#4E433D"
      />
      <path
        d="M26.2546 31.6519C26.2659 32.3297 26.8143 33.3028 28.0124 33.3113C29.2105 33.3197 29.7899 32.4703 29.7534 31.5478C29.714 30.6113 28.949 29.911 27.9449 29.925C26.9437 29.9391 26.2405 30.7125 26.2546 31.6519Z"
        fill="#B2B1B2"
      />
      <path
        d="M14.9035 24.8877C14.9035 24.8877 32.1188 24.9214 32.656 24.8877C33.1932 24.8539 33.8991 24.0805 33.9329 22.9724C33.9666 21.8643 33.7979 17.1899 33.7304 16.6527C33.6629 16.1155 33.331 15.0383 31.6801 15.0383C30.3694 15.0383 6.3282 14.9033 5.72351 14.9033C5.11883 14.9033 4.21601 15.2605 3.81383 16.2336C3.41164 17.2068 2.67195 18.9983 2.49476 21.0177C2.31476 23.0933 2.32883 24.2155 2.46383 24.7527C2.59883 25.2899 2.73383 25.8946 3.97695 25.8946H9.7932L9.86351 32.2902L14.9007 32.2677L14.9035 24.8877Z"
        fill="#454C52"
      />
      <path
        d="M35.2097 26.0831C35.2097 26.0831 34.5038 25.9677 34.3266 26.3531C34.1494 26.7384 34.1128 29.3146 34.3772 29.4271C34.6416 29.5396 35.3278 29.4524 35.3278 29.4524C35.3278 29.4524 35.3503 28.4343 35.2969 27.6946C35.2266 26.7131 35.2097 26.0831 35.2097 26.0831Z"
        fill="#DC2626"
      />
      <path
        d="M33.4323 26.3643L31.5057 26.353C31.3116 26.353 31.1541 26.1927 31.157 25.9987C31.157 25.8046 31.3145 25.6499 31.5085 25.6499H31.5113L33.4351 25.6612C33.6291 25.6612 33.7866 25.8215 33.7838 26.0155C33.781 26.2068 33.6263 26.3643 33.4323 26.3643ZM33.407 27.6327L31.4804 27.6215C31.2863 27.6215 31.1288 27.4612 31.1316 27.2671C31.1316 27.073 31.2891 26.9183 31.4832 26.9183H31.486L33.4098 26.9296C33.6038 26.9296 33.7613 27.0899 33.7585 27.284C33.7585 27.4752 33.601 27.6327 33.407 27.6327ZM33.3957 28.9068C33.3929 28.9068 33.3929 28.9068 33.3957 28.9068L31.4691 28.8955C31.2751 28.8955 31.1176 28.7352 31.1204 28.5412C31.1204 28.3471 31.2779 28.1924 31.472 28.1924H31.4748L33.3985 28.2037C33.5926 28.2037 33.7501 28.364 33.7473 28.558C33.7445 28.7521 33.587 28.9068 33.3957 28.9068Z"
        fill="#454C52"
      />
      <path
        d="M9.71428 16.5881C9.71428 16.5881 5.4899 16.5881 5.14959 16.6134C4.80928 16.6388 4.4774 17.055 4.1849 18.0731C3.87553 19.1447 3.32428 21.1781 3.45365 23.7066C3.47896 24.1763 3.92334 24.3056 4.28896 24.3056H9.73959L9.71428 16.5881Z"
        fill="#BAE6FD"
      />
      <path
        d="M5.1244 21.8841L5.04565 25.1691C5.04565 25.1691 6.42659 25.4307 6.66284 25.0651C6.89628 24.6994 6.89909 22.4073 6.71628 22.1176C6.35065 21.5438 5.1244 21.8841 5.1244 21.8841Z"
        fill="#868486"
      />
      <path
        d="M10.7043 17.3982H14.0428V31.3763H10.7325L10.7043 17.3982ZM15.0862 23.1863V16.6416H23.6925V23.161L15.0862 23.1863ZM24.7359 16.6669V23.1357H31.9331C32.6615 23.1357 32.9287 22.6997 33.0075 21.9179C33.0862 21.136 32.9962 18.1772 32.9259 17.6344C32.8472 17.0354 32.459 16.7344 31.7728 16.7035C31.0528 16.6697 24.764 16.6416 24.7359 16.6669Z"
        fill="#BAE6FD"
      />
    </svg>
  );
};

export default IconEmojiBus;
