import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';

import { AppointmentSelector } from '@assured/design-system/src/components/AppointmentSelector';
import {
  StepComponentFC,
  StepComponentSharedProps,
} from '@assured/step-renderer/types';
import { XIcon } from '@heroicons/react/solid';

import type { TimeslotSelectionStepComponentSpec } from '@assured/step-renderer';

type TimeslotSelectionProps = StepComponentSharedProps<
  TimeslotSelectionStepComponentSpec,
  string | null
> & { forceSubmit: () => void };

const TimeslotSelection: StepComponentFC<TimeslotSelectionProps> = ({
  step_component,
  forceSubmit,
  primaryValue,
  updateValue,
  className,
}) => {
  const availableSlots = useMemo(
    () =>
      step_component.available_slots.map(slot =>
        DateTime.fromISO(slot, { zone: 'local' }),
      ),
    [step_component.available_slots],
  );
  const referenceDate = useMemo(
    () => availableSlots[0].startOf('day'),
    [availableSlots],
  );
  const [selectedSlots, setSelectedSlots] = useState<DateTime[]>([]);
  const [confirmEnabled, setConfirmEnabled] = useState<boolean>(true);

  const isReady = selectedSlots.length >= step_component.minimum_slots;
  const minRemainingSlots = step_component.minimum_slots - selectedSlots.length;

  return (
    <div className={classNames('mt-6', className)}>
      <AppointmentSelector
        referenceDate={referenceDate}
        appointmentSlots={availableSlots}
        onChangeSelectedAppointmentSlot={time =>
          setSelectedSlots(prev => {
            if (!time) {
              return prev;
            } else if (prev.includes(time)) {
              return prev.filter(t => !t.equals(time));
            }
            return [...prev, time];
          })
        }
        setConfirmEnabled={setConfirmEnabled}
        selectedAppointmentSlots={selectedSlots}
        formatAppointmentSlot={time => {
          const start = time.toFormat('h a');
          const end = time
            .plus({ hours: step_component.slot_duration_hours })
            .toFormat('h a');
          return `${start} - ${end}`;
        }}
        hideAvailabilityHeader
      />
      <div className="mt-6 pt-6 border-t border-gray-200">
        <div className="mb-4 font-medium text-md text-gray-600">
          Your selected time slots
        </div>
        <div className="grid grid-cols-1 gap-2">
          {selectedSlots.map(slot => (
            <div key={slot.toISO()} className="flex items-center gap-2">
              <div className="w-2 h-2 bg-gray-400 rounded-full" />
              <div className="text-sm text-gray-600">
                {slot.toFormat('cccc, LLLL d, h a')} &ndash;{' '}
                {slot
                  .plus({ hours: step_component.slot_duration_hours })
                  .toFormat('h a')}
              </div>
              <button
                type="button"
                onClick={() =>
                  setSelectedSlots(prev => prev.filter(t => !t.equals(slot)))
                }
              >
                <XIcon className="w-4 h-4 text-gray-400 hover:text-gray-500 cursor-pointer" />
              </button>
            </div>
          ))}
          {Array.from({ length: minRemainingSlots }).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`filler-${index}`} className="flex items-center">
              <div className="bg-gray-200 w-2 h-2 rounded-full mr-2" />
              <div className="text-sm text-gray-400">
                Select a time slot above
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-6">
        <button
          onClick={() => forceSubmit()}
          className={classNames('btn btn-blue', !isReady && 'btn-disabled')}
          disabled={!isReady || !confirmEnabled}
          type="submit"
        >
          {isReady
            ? 'Continue'
            : `Select ${minRemainingSlots} more time slot${
                minRemainingSlots === 1 ? '' : 's'
              }`}
        </button>
      </div>
    </div>
  );
};

export default TimeslotSelection;
